import React from "react";
import "./aboutus.css";
import Coti from "../img/Coti.jpg";
import Javi from "../img/Javi.jpg";
import Luis from "../img/Luis.jpg";
import Boris from "../img/Boris.jpg";
import Puntos from "../img/puntos.png";
import NavBar from "../navbar/navbar";
import Contact from "../contact/Contact";
import Fotr from "../Footer/footer";

const About = () => {
  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />

      <div className="centerin2" style={{}}>
        <section className="sectionus">
          <article className="article-us">
            <h1 className="fuenteh22">Misión</h1>

            <p className="fuente2">
              {" "}
              Nuestra misión es conectar psicólogos/as y usuari@s de forma
              simple y eficaz. Utilizamos nuestra experiencia y herramientas de
              Machine Learning, para analizar la compatibilidad y calidad de la
              conexión.{" "}
            </p>
          </article>
          <article className="article-us">
            <h1 className="fuenteh22">Visión</h1>
            <p className="fuente2">
              {" "}
              Aspiramos a ser la plataforma que conecte a profesionales
              independientes con usuarios/as. Para conseguir esto, nuestro
              comrpomiso es con nuestr@s usuari@s, velando por el cumplimiento
              de una experiencia óptima. Todo esto basado en nuestros valores,
              un uso ético de la IA y una constante actualización del
              conocimiento y herramientas tecnológicas.{" "}
            </p>
          </article>
        </section>

        <section className="sectionus" style={{ display: "block" }}>
          <h1 className="fuenteh22">Equipo Fundador</h1>
          <article className="artus">
            <div className="profileus">
              <div className="imgframe">
                <img className="dots-us" src={Puntos} />
                <img
                  className="img-circular-us"
                  src={Javi}
                  alt="Descripción de la imagen"
                />
              </div>

              <div className="textframe-us">
                <h2>Javiera Cerda</h2>
                <p>
                  Psicóloga Clínica Infanto-juvenil de la UC, con más de 9 años
                  de experiencia en el sector público y privado.
                </p>
              </div>
            </div>

            <div className="profileus">
              <div className="imgframe">
                <img className="dots-us" src={Puntos} />
                <img
                  className="img-circular-us"
                  src={Luis}
                  alt="Descripción de la imagen"
                />
              </div>

              <div className="textframe-us">
                <h2>Luis Alvarez</h2>
                <p>
                  Ingeniero Electrónico de la UTFSM, con más de 5 años de
                  experiencia en el área de tecnología.
                </p>
              </div>
            </div>

            <div className="profileus">
              <div className="imgframe">
                <img className="dots-us" src={Puntos} />
                <img
                  className="img-circular-us"
                  src={Coti}
                  alt="Descripción de la imagen"
                />
              </div>

              <div className="textframe-us">
                <h2>Constanza Ernst</h2>
                <p>
                  Psicóloga formada en la UDP, con más de 9 años de experiencia
                  en sector particular y privado.
                </p>
              </div>
            </div>

            <div className="profileus">
              <div className="imgframe">
                <img className="dots-us" src={Puntos} />
                <img
                  className="img-circular-us"
                  src={Boris}
                  alt="Descripción de la imagen"
                />
              </div>

              <div className="textframe-us">
                <h2>Boris Vidal</h2>
                <p>
                  Ingeniero Electrónico de la UTFSM, con más de 4 años de
                  experiencia en el área de tecnología.
                </p>
              </div>
            </div>
          </article>
        </section>
      </div>
      <Contact />
      <Fotr />
    </div>
  );
};

export default About;
