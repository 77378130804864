import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";
import "./Calendario.css";


export function CalendarPills(
  hours,
  loggedIn,
  setDate,
  onWorkspace,
  services,
  nombre,
  apellido,
  servicename,
) {
  const [selectedHours, setSelectedHours] = useState("");

  const removeEvent = (event_id) => {
    axios
      .post(
        "/api/delete_timeslot",
        { id: event_id },
        {
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
        },
      )
      .then(() => console.log("removed"))
      .catch((error) => console.log(error));
  };

  const getServiceIndex = (service_id) => {
    if (services === undefined) return 0;
    const idx = services.findIndex(
      (service) => service["service_id"] == service_id,
    );
    return parseInt(idx) + 1;
  };

  const getStrHour = (hour) => {
    const start = new Date(hour["start"]).toLocaleString("es", {
      hour: "numeric",
      minute: "numeric",
    });

    return start;
  };

  return hours.map((day, idx) => (
    <div key={idx} className="card mr-2 dayweek" style={{ flex: 1 }}>
      <h5 className="card-title wktitl">
        {day["date"].toLocaleDateString("es", {
          weekday: "short",
          day: "numeric",
        })}
      </h5>
      <div className="">
        <div className="card-body dayweekbd">
          <ul className="list-unstyled">
            {day["hours"].length > 0 &&
            day["hours"].filter((e) => e["status"] == 0).length != 0
              ? day["hours"].map((hour, index) => {
                  if (hour["status"] != 0) {
                    return;
                  }
                  return (
                    <li
                      key={index}
                      className={`rounded-pill-custom px-3 py-1 my-1 ${selectedHours === hour["cal_id"] ? "selected-hour" : "default-hour"}`}
                      onClick={() => setSelectedHours(hour["cal_id"])}
                    >
                       <div style={{ padding: "0%", margin: "0% auto", textAlign:"center" }}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            loggedIn
                              ? ""
                              : "https://wa.me/56962420102?text=Hola quiero agendar una atención de " +
                                servicename +
                                " con " +
                                nombre +
                                " " +
                                apellido +
                                " el día " +
                                day["date"].toLocaleDateString("es", {
                                  weekday: "long",
                                  month: "long",
                                  day: "numeric",
                                }) +
                                " a las " +
                                getStrHour(hour)
                          }
                          className=" text-reset"
                          style={{ display: "block" }}
                        >
                          <div style={{ padding: "0%", margin: "0% auto",textAlign:"center" }}>
                            {getStrHour(hour)}
                          </div>{" "}
                        </a>
                        <div
                          style={{
                            display: onWorkspace && loggedIn ? "block" : "none",
                          }}
                        >
                          <div>
                            {"(" + getServiceIndex(hour["service_id"]) + ")"}
                          </div>
                          <i
                            className={`fa-solid fa-trash`}
                            onClick={() => {
                              removeEvent(hour["cal_id"]);
                              setDate(new Date(hour["start"])); // Actualizar la fecha de comienzo para forzar el re-render
                            }}
                          ></i>
                        </div>
                      </div>
                    </li>
                  );
                })
              : <span className="spannohorario" >sin horarios</span>}
          </ul>
        </div>
      </div>
    </div>
  ));
}
