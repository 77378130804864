import React from "react";
import Maptutorial1 from "../img/Maptutorial1.png";
import Maptutorial2 from "../img/Maptutorial2.png";


const TutorialMap= () =>{

  return(
<div>
<section>
    <p>Para agregar un mapa en tu perfil debes ir a <a href="https://www.google.cl/maps">Google Maps</a> desde un PC y seguir los siguientes pasos:</p>
    <article style={{textAlign:"left"}}>
    <p>1.- Buscar la dirección de tu consulta presencial</p>
    <p>2.- Seleccionar compartir</p>

    <img src={Maptutorial1}   className="imglnd" alt="Mapa consulta" style={{maxWidth:"35%"}} />

        
    <p>3.- Insertar un Mapa</p>
    <p>4.- Copiar HTML</p>

     <img src={Maptutorial2}   className="imglnd" alt="Mapa consulta presencial"style={{maxWidth:"35%"}}/>
    <p>5.- Ir a tu perfil de vincularse y presionar agregar mapa</p>
    <p>6.- Pegar el texto completo y guardar</p>
    </article>
</section>

</div>
  ) ; 
}
export default TutorialMap