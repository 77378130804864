import React from 'react';
import "../login/login.css";
import "./agendamiento.css"
// eslint-disable-next-line react/prop-types
const InputField = ({  type, value, onChange, error, placeholder }) => (
  <>

    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className='form-control inputagendamiento'
    />
    {error && <span style={{ color: "red", fontSize: "0.875rem" }}>{error}</span>}
  </>
);

export default InputField;  
