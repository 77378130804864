import React, { useEffect, useState,} from "react";
import { useParams } from "react-router-dom";
import "../Profile/profile.css";
import { Onlinepresencial } from "../Profile/onlinepresencial";
import { Basicprofile } from "../Profile/Basicprofile.js";
import axios from "axios";

function AgendaPublica() {
  const { user_id } = useParams();
  const [perfildata,setPerfildata]=useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/psfeeder/${user_id}`);
        setPerfildata(response.data);

        console.log("Respuesta psfeeder:", response.data);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        
      } finally {
        console.error("Carga finalizada");
      }
    };
    fetchData();
  }, []);
  return (
    <div>
         <div className="row no-gutters perfilcontenedor" style={{ height:"fit-content"}}>

        <div className="col-lg-3" >
          <Basicprofile
            user={"user"}
            titulo={perfildata["title"]}
            nombre={perfildata["name"]}
            apellido={perfildata["last_name"]}
            genero={perfildata["gender"]}
            exp={perfildata["years_of_experience"]}
            enfoque={perfildata["approach"]}
            minsal={perfildata["minsal_reg_no"]}
            nacionalidad={perfildata["nationality"]}
            img={perfildata["profile_picture"]}
            session={false}
          />
        </div>

        <div className="col-lg-9 perfilcentral" >
 

          <div style={{ background: "white" ,height:"100%"}}>
            <div
              className="boxprofile"
              style={{
                marginLeft: "0.5%",
                marginRight: "0.5%",
                padding: "2%",
                display: "block" , height:"100%"
                
              }}
            >
              

            <article
              className="costos mt-1 boxprofile"
              style={{ marginLeft: "0.5%", marginRight: "0.5%", padding: "2%" }}
            >
              <Onlinepresencial
                Nombre={perfildata["name"]}
                apellido={perfildata["last_name"]}
                session={false}
              />
            </article>
          </div>
        </div>

        {/* <div className="col-lg-3 terceracol">
          <div className="card">
          
            <div className="card-body"></div>
          </div>
        </div> */}
      </div>
    </div>
    </div>
    );
}

export default AgendaPublica;