import React, { useEffect, useState } from "react";
import NavBar from "../navbar/navbar";
import "./Catalog.css";

import { Card } from "./card.js";
import { useLocation } from "react-router-dom";
import Fotr from "../Footer/footer.js";
import axios from "axios";

const Catalog = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const match = queryParameters.get("match");
  const location = useLocation();
  const searchTerm = location.state?.query || "";
  const [searchResults, setSearchResults] = useState([]);
  const [respuestaT, setRespuestaT] = useState({});

  const updateResult = (data) => {
    setSearchResults(
      data.map((psico) => 
        Card(
          psico["profile_picture"],
          psico["name"]
            ? psico["name"].length + psico["last_name"].length > 25
              ? (psico["name"].length > 10
                  ? psico["name"] + "..."
                  : psico["name"]) +
                " " +
                (psico["last_name"].length > 10
                  ? psico["last_name"].substr(0, 8) + "..."
                  : psico["last_name"])
              : psico["name"] + " " + psico["last_name"]
            : "",
          psico["title"] ? psico["title"].split(",").join(", ") : "",
          psico["grade"],
          psico["years_of_experience"],
          psico["min_price"],
          psico["modalidad"],
          psico["link"],
          psico["keyitems"]
            ? psico["keyitems"].split(";;").slice(0, 3)
            : undefined,
        ),
      ),
      setRespuestaT(data),
    );
  };

  useEffect(() => {
    if (match != undefined) {
      console.log("match", match)
      axios
        .post(`/api/match/${match}`)
        .then((response) => {
          console.log(response.data);
          updateResult(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else if (searchTerm != "") {
      axios
        .post("/api/selection", searchTerm)
        .then((response) => {
          updateResult(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />
      {/* <div className="catalog__main"> */}
      <div
        className="container-fluid"
        style={{ margin: " 0 auto", display: "block", padding: "0%" }}
      >
        <div className="row no-gutters">
          <section className="col-lg-2"></section>
          <section className="col-lg-8">
            <article className="otro">
              {/* <article className="row row-cols-1 row-cols-md-3 justify-content-center"> */}
              <article className="row row-cols-2 row-cols-md-3 justify-content-center">
                {respuestaT == "" ? (
                  <div className="catalogblock">
                    <p className="fuente2">
                      No hemos podido encontrar psicóloga o psicólogo para ti,
                      para poder ayudarte te pedimos que vuelvas atrás y nos des
                      un poco más de detalle
                    </p>
                    <a href="/">
                      <button className="boton-ovalado">
                        <b>Volver</b>{" "}
                      </button>
                    </a>
                  </div>
                ) : (
                  searchResults.map((item, idx) => (
                    <div className="cardcatalogcontainer" key={idx}>
                      {item}
                    </div>
                  ))
                )}
              </article>
            </article>
          </section>
          <section className="col-lg-2"></section>
        </div>
      </div>

      {/* </div> */}
      <Fotr />
    </div>
  );
};

export default Catalog;
