import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import Logo from "../img/LogoVincularse.png";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import "./login.css";
import { UserContext } from "./login-context";

import { FormProvider, useForm } from "react-hook-form";


const Login = ({ switchModal }) => {
  Login.propTypes = {
    switchModal: PropTypes.func,
  };
  const closeRef = useRef();
  const [correo, setCorreo] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext)

  const openRegistration = () => {
    closeRef.current.click();
    switchModal();
  };

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit
  } = useForm()


  const enviarFormulario = handleSubmit((data) => {
    const formData = {
      correo: correo,
      password: data.pass,
    };
    axios
      .post("/api/login", formData)
      .then((response) => {
        // Set auth token
        Cookies.set("logged_in", true), { secure: true };
        // Close modal before navigating
        closeRef.current.click();
        // Set current logged in user
        setUser({
          logged_in: true,
          guid: response.data.guid,
          profile_link: response.data.profile_link,
          is_admin: response.data.is_admin
        });
        Cookies.set("user", response.data.profile_link), { secure: true };
      })
      .catch(() => {
        setError("pass", {
          type: "custom",
          message: "Email/Contraseña incorrectos"
        })
      });
  });

  // modal 
  useEffect(() => {
    const handlePopState = () => {
      // Buscar y cerrar manualmente todos los modales abiertos
      const modals = document.querySelectorAll('.modal.show');
      modals.forEach(modal => {
        // Simular un clic en el botón de cerrar del modal
        const closeButton = modal.querySelector('[data-bs-dismiss="modal"]');
        if (closeButton) {
          closeButton.click();
        }
      });

      // Eliminar manualmente todos los backdrops
      const backdrops = document.querySelectorAll('.modal-backdrop');
      backdrops.forEach(backdrop => backdrop.remove());
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className="form">
      <div className="modal-header">
        <img
          className="imghead"
          href="/"
          src={Logo}
          alt="Descripción de la imagen"
          style={{ maxWidth: "50%" }}
        />

        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          ref={closeRef}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <FormProvider>
        <form 
          onSubmit={(e) => e.preventDefault()}
          className="login-form"
        >
          <div className="mb-3">
            <input
              id="email-login"
              type="email"
              className={"form-control " + (errors.pass ? "is-invalid": "")}
              onChange={(e) => setCorreo(e.target.value)}
              placeholder="Correo"
            />
          </div>
          <div className="mb-3">
            <input
              id="password-login"
              type="password"
              className={"form-control " + (errors.pass ? "is-invalid": "")}
              placeholder="Contraseña"
              {...register("pass")}
            />
            {errors.pass && <div className="invalid-feedback">{errors.pass.message}</div>}
          </div>
          <button className="button-login" onClick={enviarFormulario}>
            Iniciar sesión
          </button>
        </form>
      </FormProvider>

      <p className="message">
        ¿No estás registrado?{" "}
        <a href="#" onClick={openRegistration}>
          Registrarse
        </a>
      </p>
      <p className="message">
        <a href="/recover_password">Recuperar Contraseña</a>
      </p>
    </div>
  );
};
export default Login;
