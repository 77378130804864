import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { WeeklyCalendar } from "./WeeklyCalendar";
import { UserContext } from "../login/login-context";
import "./week.css";
// import { CalendarPillsWksp } from "./CalendarPillsWorkspace";
import PropTypes from "prop-types";
import "./WeeklyCalendar.css";

// eslint-disable-next-line no-unused-vars
function WeeklyWksp({ serviceId, services, date, setDate, setdateInput }) {
  WeeklyWksp.propTypes = {
    serviceId: PropTypes.number,
    services: PropTypes.array,
    date: PropTypes.object,
    setDate: PropTypes.func,
    setdateInput: PropTypes.func,
  };
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const { user_id } = useParams();

  useEffect(() => {
    if (user && user.logged_in) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);

  const [dailyCalendar, setDailyCalendar] = useState([]);

  useEffect(() => {
    let profileLink = user_id;
    if (loggedIn) {
      profileLink = user.profile_link;
    }
    if (profileLink === undefined) {
      return;
    }
    const start_week = new Date(date.toDateString());
    // El getDay() se basa en el Domingo (0) como inicio de semana, hay que hacer un ajuste
    // para comenzar la semana el lunes
    start_week.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    const end_week = new Date(start_week);
    end_week.setDate(end_week.getDate() + 8);
    axios
      .post("/api/calendarfeeder/" + profileLink, {
        start_date: start_week,
        end_date: end_week,
        service_id: serviceId,
      })
      .then((response) => {
        buildDailyCalendar(response.data, start_week);
      })
      .catch((error) => console.log(error));
  }, [date, serviceId]);

  const buildDailyCalendar = (calendar, start_week) => {
    const cal = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(start_week);
      day.setDate(day.getDate() + i);
      const next_day = new Date(day);
      next_day.setDate(next_day.getDate() + 1);
      cal.push({
        date: day,
        hours: calendar.filter((event) => {
          const start = new Date(event["start"]);
          if (start >= day && start < next_day) {
            return true;
          }
          return false;
        }),
      });
    }
    setDailyCalendar(cal);
    console.log(cal);
  };

  return (
    <div
      className="container_week"
      style={{ maxWidth: "fit-content", padding: "0%" ,paddingTop:"15px"}}
    >
      <h5 className="title-sectionprofile pref redh5">
        Horario semanal{" "}
        {date.toLocaleDateString("es", {
          month: "long",
          year: "numeric",
        })}
      </h5>

      <div
        className="d-flex justify-content-between"
        style={{ width: "200px", padding: "15px", margin:"0 auto" }}
      >
        <span style={{ marginRight: ".5rem" }}>
          <i
            className="fa-solid fa-angles-left"
            style={{ color: "#ffb013" }}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setDate(newDate.getDate() - 7);
              setDate(newDate); // Para forzar la carga de la vista del calendario
              console.log(services)
            }}
          ></i>
        </span>
        {/* {CalendarPillsWksp(
          dailyCalendar,
          loggedIn,
          setDate,
          serviceId === undefined,
          services,
        )} */}
              Cambiar Semana
        <span>
          <i
            className="fa-solid fa-angles-right"
            style={{ color: "#ffb013" }}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setDate(newDate.getDate() + 7);
              setDate(newDate); // Para forzar la carga de la vista del calendario
            }}
          ></i>
        </span>
        
      </div>
      <div className="calendarweekcontainer">
        <WeeklyCalendar hours={dailyCalendar} setdateInput={setdateInput} setDate={setDate} services={services}/>
      </div>

    </div>
  );
}

export default WeeklyWksp;
