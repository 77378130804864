import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { UserContext } from "../login/login-context";
import "./week.css";
import { CalendarPills } from "./CalendarPills";
import PropTypes from "prop-types";

function Weekly({ serviceId, services, date, setDate, nombre, apellido, servicename }) {
  Weekly.propTypes = {
    serviceId: PropTypes.number,
    nombre: PropTypes.string,
    apellido: PropTypes.string,
    servicename: PropTypes.string,
    services: PropTypes.array,
    date: PropTypes.object,
    setDate: PropTypes.func,
  };
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const { user_id } = useParams();

  useEffect(() => {
    if (user && user.logged_in) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);

  const [dailyCalendar, setDailyCalendar] = useState([]);

  useEffect(() => {
    let profileLink = user_id;
    if (loggedIn) {
      profileLink = user.profile_link;
    }
    if (profileLink === undefined) {
      return;
    }
    const start_week = new Date(date.toDateString());
    // El getDay() se basa en el Domingo (0) como inicio de semana, hay que hacer un ajuste
    // para comenzar la semana el lunes
    start_week.setDate(date.getDate());
    const end_week = new Date(start_week);
    end_week.setDate(end_week.getDate() + 8);
    axios
      .post("/api/calendarfeeder/" + profileLink, {
        start_date: start_week,
        end_date: end_week,
        service_id: serviceId,
      })
      .then((response) => {
        buildDailyCalendar(response.data, start_week);
      })
      .catch((error) => console.log(error));
  }, [date, serviceId]);

  const buildDailyCalendar = (calendar, start_week) => {
    const cal = [];
    for (let i = 0; i < 5; i++) {
      const day = new Date(start_week);
      day.setDate(day.getDate() + i);
      const next_day = new Date(day);
      next_day.setDate(next_day.getDate() + 1);
      cal.push({
        date: day,
        hours: calendar.filter((event) => {
          const start = new Date(event["start"]);
          if (start >= day && start < next_day) {
            return true;
          }
          return false;
        }),
      });
    }
    console.log(cal)
    setDailyCalendar(cal);
  };

  return (
    <div
      className="container_week"
      style={{ maxWidth: "fit-content", padding: "0%" }}
    >
      <h5 className="title-sectionprofile pref titlepubliccalendar">
        Horario disponible{" "}
        {date.toLocaleDateString("es", {
          month: "long",
          year: "numeric",
        })}
      </h5>

      <div
        className="d-block justify-content-between"
        style={{ width: "98.4%", padding: "5px", display: "block" }}
      >
        <div
          style={{
            marginRight: ".5rem",
            justifyContent: "space-between",
            width: "40%",
            display:"flex",
            margin:"0 auto",
            marginBottom:"5px"
          }}
        >
          <i
            className="fa-solid fa-angles-left"
            style={{ color: "#ffb013" }}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setDate(newDate.getDate() - 5);
              setDate(newDate); // Para forzar la carga de la vista del calendario
            }}
          ></i>
          <span style={{ color: "black" }}>Cambiar días</span>
          <i
            className="fa-solid fa-angles-right"
            style={{ color: "#ffb013" }}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setDate(newDate.getDate() + 5);
              setDate(newDate); // Para forzar la carga de la vista del calendario
            }}
          ></i>
        </div>
        <div className="d-flex" style={{ minWidth: "98%", maxWidth:"98%", width:"98%" }}>
          {CalendarPills(
            dailyCalendar,
            loggedIn,
            setDate,
            serviceId === undefined,
            services,
            nombre,
            apellido,
            servicename
          )}
        </div>
      </div>
      

    </div>
  );
}

export default Weekly;
