/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import {  password_validation } from "../registration/validators";
import { Input } from "../registration/Input";

import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import "../login/login.css";
import Cookies from "js-cookie";



// import { UserContext } from "../login/login-context";

export function Formaccount({

  name: initalname,
  lastname: initialastname,
email:initialemail
}) {
 
  useEffect(() => {
    setName(initalname);
  }, [initalname]);
 
const[name,setName]=useState("")
const methods = useForm();
useEffect(() => {
  setLastName(initialastname);
}, [initialastname]);

const[lastname,setLastName]=useState("")

useEffect(() => {
  setEmail(initialemail);
}, [initialemail]);

const[actualizado,setActualizado]=useState(false)
const[email,setEmail]=useState("")
const[error,setError]=useState("")
const[errorauth,setErrorauth]=useState("")
const [editmail,setEditmail]=useState(false)
const [editpswd,setEditPswd]=useState("")
const[pswd,setPswd]=useState("")
const[newpswd,setNewpswd]=useState("")
const [changedinfo,setChangedinfo]=useState(false)
const[newpswdconf,setNewpswdconf]=useState("")


const validatepsw = (pswd_, newpswd_) => {
  
if (pswd_===""){return ""}
  if (pswd_.length <8) {
    return "Largo mínimo de 8 caracteres";
  } else if (newpswd_==="") {  // Corregido: Debe ser newpswd_
    return "Debes confirmar la contraseña";
  } else if (pswd_!==newpswd_) {
    return "Las contraseñas deben ser iguales";
  }

  return "";
};

const saveDataaccount = (pswd, newpswd,newpswdconf, email) => {
  setError(validatepsw(newpswd, newpswdconf));
  const data={"password":pswd,"newpasword":newpswd,"newemail":email}
  if (error===""){
  udapte_account_info(data)}

};


const saveData = () => {
  update_info({ name: name }, setName, name);

  // update_info({approach: "Psicoanálisis"}, setEnfoque, enfoque);
  //  update_info({title: "Infantil"}, setEdad, edad);
  update_info({ last_name: lastname}, setLastName , lastname)
  setActualizado(true);}
  ;

const update_info = (info, setText, text) => {
  axios
    .post("/api/update_user_info", info, {
      withCredentials: true,
      headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      
    },
  setActualizado(true))
    .then((response) => {
      console.log(response);
      setText(text);
    })
    .catch((error) => console.log(error));
};

const udapte_account_info = (data) => {
  axios
    .post("/api/edit_acc", data, {
      withCredentials: true,
      headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
    })
    .then((response) => {
      console.log(response);
      setEditPswd(false);
      setEditmail(false);
      setChangedinfo(true);
      
    })
    .catch((error) => {console.log(error)},setErrorauth(error));
};



  

 
  return (
    <div className="form"style={{width:"100%", margin:"0 auto", height:"fit-content"}}>
      <div className="modal-header" style={{ height: "20px", padding: "3px" }}>
        {/* <button

          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"

          style={{padding:"10px"}}
        >
          <span aria-hidden="true">&times;</span>
        </button> */}
      </div>
      <form className="editarperfilmodal account-edit" onSubmit={(e) => e.preventDefault()}>
        {/* <input className="multicheck"  type="checkbox" value="" id="preference1"/> */}
          <h5 style={{fontSize:"13px",color:" rgb(87, 86, 86)"}}>Configurar la información de mi cuenta</h5>
   
        <article >
       
          <div className="mb-2">
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Nombre:
            </p>

            <input
              id="workspace-name"
              value={name || ""}
              type="text"
              onChange={(e) =>{ setName(e.target.value),setActualizado(false)}}
              placeholder="Años de experiencia (solo número)"
            />
          </div>
          <div className="mb-2">
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Apellido:
            </p>

            <input
              id="workspace-lastname"
              value={lastname || ""}
              type="text"
              onChange={(e) => {setLastName(e.target.value),setActualizado(false)}}
              placeholder="Años de experiencia (solo número)"
            />
          </div>
<p> {actualizado?"información Actualizada":""}</p>
          <button className="button-login fullscreenbut"  onClick={saveData}>
            Actualizar Nombre
          </button>
          <div className="mb-2">
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Actualizar Correo:
            </p>

            <input
              id="workspace-email"
              value={email || ""}
              type="email"
              onChange={(e) => (setEmail(e.target.value),setEditmail(true),setChangedinfo(false))}
              placeholder="email"
            />
          </div>
         
          <div className="mb-2">
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Cambiar contraseña:
            </p>
            <input
              id="workspace-new-password"
              type="password"
              onChange={(e) => (setNewpswd(e.target.value),setEditPswd(true),setChangedinfo(false))}
              placeholder="Nueva contraseña"
            />
          </div>
     
          <div className="mb-2"  style={{display: editpswd === true ? "block" : "none"}}>


            <input
              id="workspace-repeat-password"
              type="password"
              onChange={(e) => (setNewpswdconf(e.target.value),setChangedinfo(false))}
              placeholder="Confirmar nueva contraseña"

            />
          </div>
          {error&&<p style={{fontSize:"10px",color:"red"}}>{error}</p>}
          <div className="mb-2"  style={{display: editmail||editpswd === true? "block" : "none"}}>
           

            <input
              id="workspace-old-password"
              type="password"
              onChange={(e) => setPswd(e.target.value)}
              placeholder="Contraseña Actual"
            />
          </div>
         
<p> {changedinfo===true?"información Actualizada":""}</p>
          <button className="button-login fullscreenbut" onClick={()=>saveDataaccount(pswd,newpswd,newpswdconf,email)}>
            Actualizar información de la cuenta
          </button>
        

       
        
        </article>
      </form>
      
    </div>
  );
}
