import React, { useState } from 'react';
import Form from './FormAgendar';
import PropTypes from "prop-types";
import Logo from "../img/LogoVincularse.png";

const FormHora = ( handleModalAgendar, txt ) => {
  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);

    FormHora.propTypes = {
  
  handleModalAgendar: PropTypes.func,
    
  }

 // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  const handleSave = (newItem) => {
    if (editingItem) {
      setItems((prev) =>
        prev.map((item) => (item.id === editingItem.id ? { ...item, ...newItem } : item))
      );
      setEditingItem(null);
    } else {
      setItems((prev) => [...prev, { ...newItem, id: Date.now() }]);
    }
  };



  return (
    <div style={{ padding: "2rem", paddingTop:"1rem", paddingBottom:"1rem" }}>
      <h2 style={{display:"block", margin:"0 auto", textAlign:"center", marginBottom:"10px"}}>Tus datos para empezar el Match</h2>

      <Form handleModalAgendar={handleModalAgendar} textopaciente={txt}/>
      
      <div style={{  margin:"0 auto",textAlign:"center" }}>
       <img
          
          href="/"
          src={Logo}
          alt="Descripción de la imagen"
          style={{ maxWidth: "30%",marginTop:"12px"}}
        />
     </div>
    </div>
    
  );
};

export default FormHora;