import React, { useState, useEffect } from "react";
import axios from "axios";

import "./week.css";
import PropTypes from "prop-types";
import WeeklyWksp from "./Weekly7.js";

import Modal_ from "../Modals/Modal_";
import { EditCalendarModal } from "./EditCalendarModal";

function WorkspaceCalendar({ profileLink }) {
  WorkspaceCalendar.propTypes = {
    profileLink: PropTypes.string,
  };

  const [services, setServices] = useState([]);
  const [date, setDate] = useState(new Date());
  const [dateInput, setdateInput] = useState(new Date());
  useEffect(() => {
    if (profileLink === undefined || profileLink == "") {
      return;
    }
    updateServices(profileLink);
    setDate(new Date());
  }, [profileLink]);

  const updateServices = (profileLink) => {
    axios
      .post(`/api/servicesfeeder/${profileLink}`)
      .then((response) => {
        setServices(
          response.data.map((item) => {
            return { ...item, checked: false };
          }),
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
       
      </div>
    
      <WeeklyWksp
        date={date}
        setDate={setDate}
        services={services}
        setdateInput={setdateInput}
      />

      <Modal_ isOpen={true} id="editCalendarModal">
        <EditCalendarModal
          services={services}
          setServices={setServices}
          setDate={setDate}
          dateInput={dateInput}
        />
      </Modal_>

      
    </div>
  );
}

export default WorkspaceCalendar;
