import React, { useState, useContext, useEffect } from "react";

import axios from "axios";
import TutorialMap from "./maptutorial.js"

import { UserContext } from "../login/login-context";
import { useLocation, useNavigate } from "react-router-dom";

import { Formaccount } from "./config_profile.js";
import { HomePost } from "./home_post.js";
import { NavBarSup } from "../navbar/navbarsup.js";

import Fotr_log from "../Footer/footerlog";

import "../Profile/profile.css";
import "./workspace.css";
import { WorkspaceNav } from "./WorkspaceNav.js";
import WorkspaceCalendar from "../agenda-calendario/WorkspaceCalendar.js";

function Workspacehome() {
  const location = useLocation();
  const [selectedPestana, setSelectedPestana] = useState(
    location.state?.tab || "home",
  );
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const [loggedIn, setLoggedIn] = useState(false);
  const [profilelink, setProfilelink] = useState("");

  useEffect(() => {
    if (user && user.logged_in && user.profile_link) {
      setLoggedIn(true);
      setProfilelink(user.profile_link);
    } else {
      setLoggedIn(false);
      setProfilelink("");
    }
  }, [user]);

  useEffect(() => {
    if (!(user && user.logged_in)) {
      navigate("/");
    }
  }, [user]);
  const [to_profile_first, setToprofile] = useState("");

  useEffect(() => {
    if (to_profile_first === "true") {
      const url_path = "/ps/" + profilelink;
      navigate(url_path);
    }
  }, [to_profile_first]);

  const [info, setData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get("/api/home_feeder");
      setData(response.data);
      setToprofile(
        response.data["first_time_login"] === false ? "false" : "true",
      );
    } catch (error) {
      if (error.response.status == 401) {
        navigate("/");
      }
    }
  };

  const setDisplayed = (tab) => {
    return { display: selectedPestana === tab ? "block" : "none" };
  };

  return (
    <div
      style={{
        width: "100%",
        background: "#f7f7f7",
        height: "100%",
      }}
    >
      <div className="row no-gutters perfilcontenedorwk">
        <NavBarSup profile="false" />
        <WorkspaceNav
          selectedTab={selectedPestana}
          setSelectedTab={setSelectedPestana}
          profileLink={profilelink}
        />

        

        <div className="col-lg-10 perfilcentralwk">
          <article style={setDisplayed("config")}>
            <Formaccount
              name={info["name"]}
              lastname={info["lastname"]}
              email={info["email"]}
            />
          </article>

          <article style={setDisplayed("home")}>
            <HomePost name={info["name"]} />
          </article>

          <article style={setDisplayed("agenda")}>
            <WorkspaceCalendar profileLink={profilelink} />
          </article>


          <article style={setDisplayed("help")}>
            <TutorialMap />
          </article>
        </div>
      </div>

      <Fotr_log />
    </div>
  );
}

export default Workspacehome;
