import { useState, useContext, useEffect } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { es } from "date-fns/locale/es";
import axios from "axios";
import Cookies from "js-cookie";

import { UserContext } from "../login/login-context";

registerLocale("es", es);
setDefaultLocale("es", es);

function Dashboard() {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);

  const logOut = () => {
    Cookies.remove("logged_in");
    Cookies.remove("user");
    Cookies.remove("csrf_access_token");
    Cookies.remove("token");
    setUser({ logged_in: false });
    // Redirige al usuario a la página de inicio
    window.location.href = "/";
  };

  const AGENDADO = "agendado";

  const [userList, setUserList] = useState([]);
  const [lastActivity, setLastActivity] = useState([]);
  const [userToModify, setUserToModify] = useState("");
  // const [calendarId, setCalendarId] = useState();
  // const [calendarList, setCalendarList] = useState([]);
  const [calendarStatus, setCalendarStatus] = useState(AGENDADO);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [updateStatus, setUpdateStatus] = useState("");

  const getUserList = async () => {
    try {
      const response = await axios.get("/api/get_user_list");
      setUserList(response.data);
    } catch (error) {
      console.log("ERROR");
    }
  };

  useEffect(() => {
    if (user && user.logged_in == false) {
      window.location.href = "/";
    }
    getUserList();
  }, []);

  const getUserActivity = async () => {
    try {
      const response = await axios.post(
        "/api/get_last_activity",
        { guids: userList.map((item) => item.user_guid) },
        {
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
        },
      );
      setLastActivity(response.data);
    } catch (error) {
      console.log("ERROR");
    }
  };

  const updateCalendar = () => {
    axios
      .post(
        "/api/block_timeslot",
        { user_id: userToModify, start_date: startDate, end_date: endDate },
        {
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
        },
      )
      .then(setUpdateStatus("OK"))
      .catch((e) => setUpdateStatus(e.response.data));
  };

  return (
    <div>
      <div>
        <h1>Dashboard</h1>
      </div>
      <div>
        <button
          onClick={() => {
            logOut();
          }}
        >
          Logout
        </button>
      </div>
      <select
        value={userToModify}
        onChange={(e) => {
          setUpdateStatus("");
          setUserToModify(e.target.value);
        }}
      >
        <option value="">--Selecctiona Profesional--</option>
        {userList.map((item) => {
          return (
            <option key={item.user_guid} value={item.user_guid}>
              {item.name} {item.last_name}
            </option>
          );
        })}
      </select>
      <span>Inicio</span>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setUpdateStatus("");
          setStartDate(date);
        }}
        showTimeInput
        dateFormat="Pp"
        timeInputLabel="Horario"
        minDate={new Date()}
      />
      <span>Fin</span>
      <DatePicker
        selected={endDate}
        onChange={(date) => {
          setUpdateStatus("");
          setEndDate(date);
        }}
        showTimeInput
        dateFormat="Pp"
        timeInputLabel="Horario"
        minDate={new Date()}
      />
      <select
        value={calendarStatus}
        onChange={(e) => setCalendarStatus(e.target.value)}
      >
        <option value="disponible">Disponible</option>
        <option value={AGENDADO}>Agendado</option>
      </select>
      <button
        onClick={() => {
          updateCalendar();
        }}
      >
        Update cal
      </button>
      {updateStatus != "" && <span>{updateStatus}</span>}
      <div>
        <button
          onClick={() => {
            getUserActivity();
          }}
        >
          Load Last User Activity
        </button>
        <ol>
          {userList.map((item, idx) => (
            <li key={idx}>
              <a href={"https://www.vincular.se/ps/" + item.link}>
                {item.name} {item.last_name}
              </a>
            </li>
          ))}
        </ol>
        <ol>
          {lastActivity.map((item, idx) => (
            <li key={idx}>
              {item.email} - {item.activity_name} - {item.activity_timestamp}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}

export default Dashboard;
