/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import "../workspace/Work.css";
import "./profile.css";

import Cookies from "js-cookie";
import Weekly from "../agenda-calendario/Calendarioweek";

// eslint-disable-next-line no-unused-vars
export function Services({ session, nombre, apellido }) {
  //variables transitorias

  const { user_id } = useParams();
  
  //variables de trabajo
  const [action, setAction] = useState("");
  const [position, setPosition] = useState("");
  const [date, setDate] = useState(new Date());
  const [services, setServices] = useState({});
  const [selectedservice, setSelectedservice] = useState();
  const formato = new Intl.NumberFormat("es-ES", {
    style: "decimal",
    decimal: ",",
    thousand: ".",
  });
  const [name_service, setName_service] = useState(""); //Nombre del servicio
  const [type_service, setTypeservice] = useState(""); //tipo consulta Test otro
  // eslint-disable-next-line no-unused-vars
  const [service_comment, setServiceComment] = useState(""); //tipo consulta Test otro
  const [duration, setDuration] = useState(""); //duración minutos
  const [modality, setModality] = useState(""); //modalidad online preencial

  const [value_online, setValueOnline] = useState(""); //valor online

  const [value_offline, setValueOffline] = useState(""); //valor offline

  const [agregarservice, setAgregarservice] = useState(false); //valor offline

  const [serviceId, setServiceId] = useState(0);
  const navigate = useNavigate();
  // const mapasrc= "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.017528138472!2d-70.60531372374201!3d-33.42278737340049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf6c1d852723%3A0x646fd9869d253c46!2sLota%202500%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1734039985204!5m2!1sen!2scl"
  const eInfo = () => {
    setEditarinfo(!editar);
    setPosition("");
    setAgregarservice(false);
  };

  const eService = (position_, action_) => {
    setEditarstudy(true);
    setPosition(position_);
    setAction(action_);
    setAgregarservice(false);

    setName_service("");
    setModality("");
    setTypeservice("");
    setDuration("");
    setValueOffline("");
    setValueOnline("");
  };

  const sendDataedit = (
 service
    
  ) => {
    
    // const vector = {
    //   action: action,
    //   profile_link: user_id,
    //   service_name: name_service === "" ? name_service_ : name_service,
    //   service_position: position_,
    //   service_type: type_service === "" ? type_service_ : type_service,
    //   service_mod: modality === "" ? modality_ : modality,
    //   service_duration: duration === "" ? duration_ : duration,
    //   value_online: value_online === "" ? value_online_ : value_online,
    //   value_offline: value_offline === "" ? value_offline_ : value_offline,
    //   service_comment: service_comment === "" ? service_comment_ : service_comment,
    // };
    service.action=action
    console.log
    update_info(service);
    setAction("");
    setPosition("");
    setEditarstudy(false);
  };

const getOffilne = services && Array.isArray(services) 
  ? services.some(obj => (obj.service_mod === "Offline"|| obj.service_mod ==="Otro")) 
  : false;

  const dStudy = (action_, position_) => {
    setDeletestudy(!deletestudy);

    const vector = {
      action: action_,
      academic_type_position: position_,
      profile_link: user_id,
    };
    update_info(vector);
  };

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Estado de carga

  const sService = (position) => {
    setSelectedservice(services[position]);
  };

  const [editar, setEditarinfo] = useState(false);
  const [editarstudy, setEditarstudy] = useState(false);
  const [deletestudy, setDeletestudy] = useState(false);
  const [adress,setAdress]=useState({adress:"",
                                    comuna:"",
                                    region:"Metroplitana",
                                    mapa:"",
                                    mapasrc:"",
                                    coordenadas:""})

  const handleChangeAdress = (field, value) => {
    if (field!=="mapasrc"){
    setAdress((prev) => ({ ...prev, [field]: value }));}
    else{

  const srcMatch = value.match(/src="([^"]+)"/);
  const mapa = srcMatch ? srcMatch[1] : null;
  console.log(mapa);
  setAdress((prev) => ({ ...prev, ["mapa"]: mapa }));
  setAdress((prev) => ({ ...prev, [field]: value }));
  return true ;
    }
  };


  const regiones =["Metropolitana","Arica y Parinacota","Tarapacá","Antofagasta","Atacama","Coquimbo","Valparaiso","O'higgins","Maule","Ñuble","Bío Bío","Araucanía","Los Ríos","Los Lagos","Aysén","Magallanes"];
  const sAgreg = (value, setAgregar) => {
    setAction("new");
    setAgregarservice(false);
    setModality("Online");
    setAgregar(value);
    setEditarstudy(false);
  };

  const sendData = (setAG, setAgr, value, type_) => {
    const vector = {
      action: action,
      profile_link: user_id,
      service_name: name_service,
      service_type: type_service,
      service_mod: modality,
      service_duration: duration,
      service_comment: service_comment,
      value_online: value_online,
      value_offline: value_offline,
    };
    setAG(value, setAgr, type_);
    update_info(vector);
    setDate(new Date()); // Para actualizar el calendario en la primera vista
  };
  const [expandedDirection,setExpandDirection]=useState(false)
const elementRef = useRef(null);
const elementRefedit = useRef(null);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

 const handleClickInside = () => {
    if(!expandedDirection){setExpandDirection(true);}
    else{update_adress(adress),setExpandDirection(false),setAdress(adress)

    }
  };

  const handleClickOutside = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setExpandDirection(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // eslint-disable-next-line no-unused-vars
  const update_info = (info) => {
    axios
      .post("/api/update_services", info, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => console.log(error));
    console.log(info);
  };

    const update_adress = (info) => {
    axios
      .post("/api/adressupdate/", info, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then()
      .catch((error) => console.log(error));
    console.log(info);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/servicesfeeder/${user_id}`);

        setServices(response.data);

        console.log("Respuesta servicesfeeder:", response.data);
      } catch (error) {
        console.error("Error al cargar servicesfeeder:", error);
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/adressfeeder/${user_id}`);

        setAdress(response.data);

        console.log("Respuesta servicesfeeder:", response.data);
      } catch (error) {
        console.error("Error al cargar servicesfeeder:", error);
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };
    fetchData();
  }, []);

const [editingservice,setEditingService]=useState([])

  const handleChange = (field, value) => {
    setEditingService((prev) => ({ ...prev, [field]: value }));
  };

useEffect(()=>{
  setEditingService(editingservice)
},[editingservice])

const setServicioeditar=(service_position)=>{
   
    setEditingService( services.find(obj => obj.service_position ===service_position))
    console.log(editingservice)
    
}


  const getCalendar = (service_id) => {
    setServiceId(service_id);
  };

  return (
    <div> 
      <div style={{ padding: "1%" }}>
        <article
          className={`headers-academic ${services.length >= 1 || editar ? "show" : "notshow"}`}
        >
<div className="divbotoneservice">

          <div>
            <button
              className={`button-login profilebutton ${session ? "show" : "notshow"}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/home", { state: { tab: "agenda" } });
                window.scrollTo(0, 0);
              }}
            >
                <i
                className={`fa-solid fa-plus show`}
                style={{ fontSize: "11px", height: "100%", marginRight:"3px"}}
              ></i>
               horario de atención{" "}
              <i
                className={`fa-solid fa-calendar show`}
                style={{ fontSize: "11px", height: "100%" }}
              ></i>
            </button>
          </div> 
           <div style={{ textAlign: "center" }}>
          <button
            className={`button-login profilebutton ${session && !editar && services.length>0 ? "show" : "notshow"} `}
            onClick={() => {
              eInfo();
              scrollToSection(elementRefedit);
            }}
          >
            {services.length>0?"Editar prestaciones":"Agregar Prestación"}
          </button>
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            className={`button-login profilebutton ${editar ? "show" : "notshow"}`}
            onClick={eInfo}
          >
            Finalizar
          </button>
        </div> </div>
      {getOffilne&&session&&<div  className="servicebox atentionplace"
                        style={{height:expandedDirection?"auto":""}} 
                        ref={elementRef}> 
                 <div style={{display:"flex", flex:"fled-end",justifyContent:"space-between"}}>
                        
                    <p style={{textAlign:"left"}}>Lugar de atención presencial </p>
                      
                 
                
                  <button
                    className="button-login profilebutton "
                    style={{display:session?"":"none", marginTop:"0%",}}
                    
                     onClick={handleClickInside}
                  >
                    <b>{expandedDirection?"Guardar":"Agregar"}</b>{" "}
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
             
            
              
                  </div>
      

                       
                        <p > <span>Dirección: <input
                        required
                        type="text"
                        defaultValue={adress.adress?adress.adress:""}
                        className={`inputservicename`}
                        onChange={(e) => handleChangeAdress("adress",e.target.value)}
                        
                      /></span></p>

                      <p > <span>Comuna: <input
                        required
                        type="text"
                        defaultValue={adress.comuna?adress.comuna:""}
                        className={`inputservicename`}
                        onChange={(e) => handleChangeAdress("comuna",e.target.value)}
                        
                      /></span>
                      </p>
                         <p style={{textAlign:"left"}}> <span>Región:
                      <select
                        id="mySelect"
                        name="mySelect"
                        defaultValue={adress.region?adress.region:"Metroplitana"}
                        onChange={(e) => handleChangeAdress("region",e.target.value)}
                      >
                         
      
                        {regiones.map((service)=> <option key={service}>{service}</option>)}
                
                      </select>
                        
                        
                      </span></p>

                        <p> <span>Si quieres agregar mapa necesitas ver el 
                          <a  onClick={(e) => {
                e.preventDefault();
                navigate("/home", { state: { tab: "help" } });
                window.scrollTo(0, 0);
              }} 
              style={{color:"#ee6c4d"}}> tutorial </a> </span></p>
                       
                        <p > <span>Mapa html: <input
                        defaultValue={adress.mapasrc?adress.mapasrc:""}
                        required
                        type="text"
                        className={`inputservicename`}
                        onChange={(e) => handleChangeAdress("mapasrc",e.target.value)}
                        
                      /></span></p>

                       
                      
                      </div>}
           

            {getOffilne&&<div  className="servicebox atentionplace"
                        style={{height:"auto"}} 
                        > 
                        
                 <div style={{display:"flex", flex:"fled-end",justifyContent:"space-between"}}>
                        
                    <p style={{textAlign:"left"}}>Lugar de atención presencial </p>
                      
                 
                
        
             
            
              
                  </div>
                  {adress.mapa&&(<div style={{margin:"0 aut", justifyContent:"center"}}><iframe
        title="google map"
         src={adress.mapa}
        width="100%"
        height="200px"
        
        style={{ margin:"0 auto "}}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> </div>)}
      

                      
                        <p > <span>Dirección: {adress.adress?adress.adress:"Consultar"}</span></p>
                         <p > <span>Comuna: {adress.comuna?adress.comuna:"Consultar"}</span></p>
                        <p > <span>Región: {adress.region?adress.region:"Consultar"}</span></p>
                    
                     
                       
                      
                      </div>}


          {/* Map de BBDD */}
          {Object.keys(services).map((indx) => (
            <div
              key={indx}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div
                className={`servicebox ${editarstudy === true && position === services[indx]["service_position"] ? "notshow" : "show"} ${selectedservice && selectedservice["service_position"] === services[indx]["service_position"] ? "selectedservice" : ""}`}
                
                onClick={() => sService(indx)}
              >
                
                {
                  <>
                    <>
                      <div
                        style={{ display: "flex" }}
                        className="pstudyindx indxdown"
                      >
                        <p className="pstudyindx">
                          {services[indx]["service_name"]}{" "}
                        </p>
                     
                      </div>
                       <div
                        style={{ display: "flex" }}
                        className="pstudyindx indxdown"
                      >
                     
                        <p
                          className="pstudyindx"
                          style={{ justifyContent: "left" }}
                        >
                         {services[indx]["service_duration"]} minutos
                        </p>
                      </div>
                         <div
                        style={{ display: "flex" }}
                        className="pstudyindx indxdown"
                      >
                        <p className="pstudyindx">
                          {services[indx]["service_comment"]?`Atención${ services[indx]["service_comment"]}`:"Sin información de la previsión de salud"}
                        </p>
                       
                      </div>

                       <div
                        style={{ display: "flex" }}
                        className="pstudyindx indxdown"
                      >
                        <p className="pstudyindx">
                          {services[indx]["service_mod"]==="Online"?"Modalidad online":services[indx]["service_mod"]==="Offline"?"Modalidad presencial":"Modalidad online y presencial"}
                        </p>
                       
                      </div>


                      <div
                        style={{ display: "flex" }}
                        className="pstudyindx indxdown"
                      >
                        {services[indx]["service_mod"] === "Online" ? (
                          <p className="pstudyindx">
                            Valor: ${" "}
                            {formato.format(services[indx]["value_online"])}{" "}
                          </p>
                        ) :services[indx]["service_mod"] === "Offline" ? (
                          
                          <p className="pstudyindx">
                            Valor: ${" "}
                            {formato.format(services[indx]["value_offline"])}{" "}
                          </p>
                        ):(  <><p className="pstudyindx">Valor online: ${formato.format(services[indx]["value_online"])}</p><p className="pstudyindx"> Valor presencial: ${formato.format(services[indx]["value_offline"])}</p></> )}
                      </div>
                    </>
                    <>
                      {serviceId != 0 &&
                      position === services[indx]["service_position"] ? (
                        <Weekly
                          serviceId={serviceId}
                          date={date}
                          setDate={setDate}
                          nombre={nombre}
                          apellido={apellido}
                          servicename={services[indx]["service_name"]}


                        />
                      ) : (
                        <></>
                      )}{" "}
                    </>
                  </>
                }
                <div style={{ textAlign: "right" }}>
                  {/* <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://wa.me/56962420102?text=Hola quisiera una hora de atención con " +
                      nombre +
                      " " +
                      apellido
                    }
                    className="me-4 text-reset"
                    style={{ display: session ? "none" : "block" }}
                  > */}
                  <button
                    className="button-login profilebutton "
                    style={{display:editar?"none":""}}
                    onClick={() => (
                      getCalendar(services[indx]["service_id"]),
                      setPosition(services[indx]["service_position"])
                    )}
                  >
                    <b>Disponibilidad</b>{" "}
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  {/* </a> */}
                </div>
                <p
                  className={`p-buttonsedit ${session && editar ? "show" : "notshow"}`}
                  style={{ textAlign: "right" }}
                >
                  <i
                    className={`fa-solid fa-pen ${editarstudy === true && position === services[indx]["service_position"] ? "notshow" : "show"}`}
                    onClick={() => {
                      eService(services[indx]["service_position"], "edit");setServicioeditar(services[indx]["service_position"])
                    }}
                    style={{ margin: "10px" }}
                  ></i>
                  <i
                    className={`fa-solid fa-trash ${editarstudy === true && position === services[indx]["service_position"] ? "notshow" : "show"}`}
                    onClick={() => {
                      dStudy("delete", services[indx]["service_position"]);
                    }}
                  ></i>

                 
                </p>
              </div>
             
              {/* form editar */}
              <div style={{ display: "block" }}>
                <article
                  className={` ${editarstudy === true && position === services[indx]["service_position"] ? "show2" : "notshow"}`}
                >
                  <div className="serviceboxform">
                    <div>
                      <input
                        required
                        type="text"
                        className={`inputservicename`}
                        onChange={(e) => handleChange("service_name",e.target.value)}
                        placeholder={services[indx]["service_name"]}
                        value={editingservice.service_name}
                      />
                      <select
                        id="mySelect"
                        name="mySelect"
                        onChange={(e) => handleChange("type_service",e.target.value)}
                      >
                        <option value="Consulta">Consulta</option>
                        <option value="Evaluación">Evaluación / Test</option>
                        <option value="Otro">Otro</option>
                      </select>
                    </div>
                    <div>
                      Duración:
                      <input
                        required
                        type="text"
                        className={`inputservicenumber2`}
                        onChange={(e) =>  handleChange("service_duration",e.target.value)}
                        value={editingservice.service_duration}
                        placeholder={services[indx]["service_duration"]}
                      />{" "}
                      minutos
                      <span>
                        {" "}
                        - Modalidad:
                        <select
                          id="mySelect"
                          name="mySelect"
                          onChange={(e) => handleChange("service_mod",e.target.value)}
                          defaultValue={
                            !modality ? services[indx]["service_mod"] : modality
                          }
                        >
                          <option value="Online">Online</option>
                          <option value="Offline">Presencial</option>
                          <option value="Otro">Ambas</option>
                        </select>
                      </span>
                    </div>
                    <div><span>Previsión de Salud: 
                       <select
                          id="mySelect"
                          name="mySelect"
                          onChange={(e) =>handleChange("service_comment",e.target.value)}
                          defaultValue={
                            !service_comment ? services[indx]["service_comment"] : service_comment
                          }
                        ><option value=""></option>
                          <option value="Fonasa">Fonasa</option>
                          <option value="Particular">Particular</option>
                          <option value="Isaprereembolso">Isapre con reembolso</option>
                        </select>

                      </span></div>
                    <div style={{ marginLeft: "0%" }}>
                      <p
                        className={`inputservicenumber ${(!modality && (editingservice.service_mod === "Online" || editingservice.service_mod === "Otro")) || (modality && (modality == "Online" || modality == "Otro")) ? "show" : "notshow"}`}
                      >
                        Valor Online:
                      </p>
                      <input
                        required
                        type="text"
                        className={`inputservicenumber ${(!modality && (editingservice.service_mod === "Online" || editingservice.service_mod === "Otro")) || (modality && (modality == "Online" || modality == "Otro")) ? "show" : "notshow"}`}
                        onChange={(e) => handleChange("value_online",e.target.value)}
                        value={editingservice.value_online}
                        placeholder=""
                      />
                      <p
                        className={`inputservicenumber ${(!modality && (editingservice.service_mod === "Offline" || editingservice.service_mod === "Otro")) || (modality && (modality == "Offline" || modality == "Otro")) ? "show" : "notshow"}`}
                      >
                        Valor Presencial:
                      </p>
                      <input
                        required
                        type="text"
                        className={`inputservicenumber ${(!modality && (editingservice.service_mod === "Offline" || editingservice.service_mod === "Otro")) || (modality && (modality == "Offline" || modality == "Otro")) ? "show" : "notshow"}`}
                        onChange={(e) => handleChange("value_offline",e.target.value)}
                        value={editingservice.value_offline}
                        placeholder=""
                      />
                    </div>
                  </div>
                </article>

                {/* botones editar y borrar */}
                  <button
                    className={`button-login profilebutton  ${editarstudy === true && position === services[indx]["service_position"] ? "show" : "notshow"}`}
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      sendDataedit(
                       editingservice
                      );
                    }}
                  >
                    <i className={`fa-solid fa-floppy-disk `}></i> Guardar
                    cambios
                  </button>
              </div>
            </div>
          ))}
        </article>
        

        {/* Formulario */}
        <article
          className={` ${editar && agregarservice ? "show" : "notshow"}`}
        >
          <div className="serviceboxform">
            <div>
              <input
                required
                type="text"
                
                className={`inputservicename ${agregarservice === true ? "show" : "notshow"}`}
                onChange={(e) => setName_service(e.target.value)}
                placeholder="Nombre de la prestación"
              />{" "}
              <span>
                {" "}
                - Tipo
                <select
                  id="mySelect"
                  name="mySelect"
                  onChange={(e) => setTypeservice(e.target.value)}
                >
                  <option value="Consulta">Consulta</option>
                  <option value="Evaluación">Evaluación / Test</option>
                  <option value="Otro">Otro</option>
                </select>
              </span>
            </div>

            <div>
              {" "}
              Duración:{" "}
              <input
                required
                type="text"
                className={`inputservicenumber ${agregarservice === true ? "show" : "notshow"}`}
                onChange={(e) => setDuration(e.target.value)}
                placeholder=""
              />{" "}
              minutos
              <span>
                {" "}
                - Modalidad:
                <select
                  id="mySelect"
                  name="mySelect"
                  onChange={(e) => setModality(e.target.value)}
                  defaultValue={"Online"}
                >
                  <option value="Online">Online</option>
                  <option value="Offline">Presencial</option>
                  <option value="Otro">Ambas</option>
                </select>
              </span>{" "}
            </div>
            <div><span>Previsión de Salud: 
                       <select
                          id="mySelect"
                          name="mySelect"
                          onChange={(e) => setServiceComment(e.target.value)}
                       defaultValue={""}
                        ><option value=""></option>
                          <option value="Fonasa">Fonasa</option>
                          <option value="Particular">Particular</option>
                          <option value="Isaprereembolso">Isapre con reembolso</option>
                        </select>

                      </span></div>

            <div>
              <p
                className={`inputservicenumber ${!modality || modality === "Online" || modality === "Otro" ? "show" : "notshow"}`}
              >
                Valor Online:
              </p>

              <input
                required
                type="text"
                className={`inputservicenumber ${agregarservice === true && (modality === "Online" || modality === "Otro") ? "show" : "notshow"}`}
                onChange={(e) => setValueOnline(e.target.value)}
                placeholder="pesos"
              />

              <p
                className={`inputservicenumber ${!modality || modality === "Offline" || modality === "Otro" ? "show" : "notshow"}`}
              >
                Valor presencial:
              </p>

              <input
                required
                type="text"
                className={`inputservicenumber ${agregarservice === true && (modality === "Offline" || modality === "Otro") ? "show" : "notshow"}`}
                onChange={(e) => setValueOffline(e.target.value)}
                placeholder="pesos"
              />
            </div>
          </div>
        </article>

        {/* botones nuevo */}
        <div style={{ textAlign: "right" }} ref={elementRefedit}>
          <button
            className={`button-login profilebutton ${editar === true && agregarservice === true ? "show" : "notshow"}`}
            onClick={() => sendData(sAgreg, setAgregarservice, false)}
            disabled={name_service.length < 1}
            style={{
              maxWidth: "fit-content",
              paddingLeft: "2%",
              paddingRight: "2%",
            }}
          >
            <i className="fa-solid fa-floppy-disk"></i> Guardar
          </button>

          <a
            className={`linkestudio ${editar && !agregarservice ? "show2" : "notshow"}`}
            onClick={() => sAgreg(true, setAgregarservice)}
            style={{ textDecoration: "underline" }}
          >
            <i className="fa-solid fa-plus"></i>
            <span style={{ margin: "5px" }} >Agregar nuevo</span>
          </a>
        </div>

       
      </div>
    </div>
  );
}
