

// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";

import PropTypes from 'prop-types';
import Cookies from "js-cookie";
// import "../workspace/Work.css";
import "./profile.css";
import axios from "axios";


export function Preferencias({ pref: initiapref, session }) {
    
    useEffect(() => {
        if (initiapref) {
          setPref(initiapref.split(",").slice(0));
        }
    }, [initiapref]);
    
      
    const[pref,setPref]=useState(initiapref?initiapref.split(","):[])
    const[mostrarmas,setMostrarmas]=useState(false)
  const mMas = () =>{
setMostrarmas(!mostrarmas)
  }
    const pref_visible=pref.lenght<6?pref:pref.slice(0,6)
    const [editar,setEditarinfo]=useState(false)
    const eInfo = () => {
        setEditarinfo(!editar);
    
      };
    // if (!pref || pref.length === 0) {   
    //     return <div>No hay items para mostrar</div>;
    // }

    // const [pref_arr,setPrefarray]=useState(pref.split(",").slice(0,6));


    // const items = pref_arr.map((item) => (
    //     <li
    //       key={item}
    //       className="rounded-pill px-3 py-1 my-1 espprof"
    //     >
    //       {item}
    //     </li>
    // ));
    // if (!pref || pref.length === 0) {
    //     return <div>No hay items para mostrar</div>;
    // }
    // const pref_arr = pref.split(",").slice(0);


    const handleChangepref = (value) => {

        setPref(prevOptions => {
          if (prevOptions.includes(value)) {  return  pref.length>1?
            prevOptions.filter(opt => opt !== value):pref;
          } 
          else if (pref.length>=10) {
            return pref
          }
          else {
            return [...prevOptions, value];
          }
     
        });
    // console.log(pref_arr)
      }
      const saveData =() =>{
        setEditarinfo(!editar);
        update_info({consultation_items: pref.join(",")},setPref,pref)
      }

      const update_info = (info, setData, data) => {
        axios
          .post("/api/update_user_info", info,
            {
              withCredentials: true,
              headers: { 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') },
          })
          .then((response) => {
            console.log(response);
            setData(data);
  
          })
          .catch((error) => console.log(error));
      }
      const Lista_preferencias =["Ansiedad","Depresión","Autismo","Déficit Atencional","Trastorno Bipolar","Duelo","Violencia","LGBTI+","Identidad de género","Separación","Crianza","Parentalidad","Maltrato infantil","Trastornos Alimentarios",
        "Fobias","Trastorno Obsesivo Compulsivo","Esquizofrenia","Consumo de sustancias","Trastorno de Personalidad","Crisis de pánico", "Crisis existenciales","Adolescencia",
        "Imagen Corporal","Suicidio","Autolesiones","Fobia escolar","Habilidades sociales","Adicciones","Abuso Sexual","Terapia de parejas",
        "Estrés crónico","Estrés laboral","Crisis vitales","Dolor crónico","Problemas conductuales","Crisis relacional"]

                            
    return(
        <div className="card-text schedu mt-4">
<div style={{display:"flex"}}>
                        <h5 className="card-title pref redh5  " style={{width:"10em"}}>Me especializo en:</h5>
                        <h5 style={{textAlign:"right",width:"5%"}}> <i className={`fa-solid fa-pen ${session &&!(editar) ? "show" : "notshow"} `} style={{fontSize:'13px'}} onClick={() => {eInfo()}} ></i></h5>
</div>
                        {pref_visible.length<1 &&<p> {session?"Selecciona los diagnósticos sueles atender o donde te sientes más cómodo atendiendo.":"Lo sentimos aún no tenemos información"}</p>}
                        <div className="variable-container2">
                        {Object.keys(pref_visible).map((indx) => (
        <div key={indx} style={{textAlign:"center"}}>
          <p className="selector-ovalado2 pref-atencion-selected postselection" >{pref[indx]}</p>
        </div>
      ))}
           <p className={`${pref.length > 6? "show" : "notshow"} `} style={{justifyContent:"right", textDecoration:"underline"}} onClick={mMas}>{mostrarmas?"Ver menos":"Ver más"}</p>
<p style={{fontStyle:"italic", display:mostrarmas?"block":"none", textAlign:"center"}}>{pref.slice(6 ,).join(", ")}</p>

 
  
</div>

<article  className={`${editar ? "show" : "notshow"} `} style={{textAlign:"center", justifyContent:"center"}}>
{/* { Object.keys(Lista_preferencias).map((indx) => (
   <ul className="list-unstyled profile_esp" key={indx} style={{display:"inline"}}><li ><p className="selector-ovalado pref-atencion">{Lista_preferencias[indx]}</p></li></ul>
))} */}
<h5 className="mt-5" style={{textAlign:"left"}}>Selecciona máximo 10:</h5>
<p className="indications">Haz click para agregar una opción nueva </p>
<p className="indications" >Haz click sobre una opción seleccionada para deseleccionar. </p>
<p className="indications">Solo tus primeras 6 selecciones serán visibles públicamente. </p>
<div className="variable-container">
    
      {Lista_preferencias.map((variable, index) => (
        <div key={index} className="">
          <p className={`selector-ovalado2 ${pref.includes(variable) ? "pref-atencion-selected" : "pref-atencion"}  ${pref_visible.includes(variable)? "postselection" : ""} `} onClick={() => handleChangepref(variable)}>{variable}</p>
        </div>
      ))}
    </div>
    <div style={{textAlign:"right"}}><button  className={`button-login profilebutton ${editar ? "show" : "notshow"}`} onClick={saveData} >
          Guardar
        </button></div>
</article>



        </div>


           );
}
Preferencias.propTypes = {
    pref: PropTypes.string,

    session: PropTypes.bool,

  };