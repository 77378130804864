import React from "react";
import PropTypes from "prop-types";
import "./Modals.css";

// eslint-disable-next-line react/prop-types
const ModalButton = ({ toShow, onClose,saveData,tipomodal,setDate,getCheckedServices ,services}) => {
  if (!toShow) return null;
  const children =(modaltype)=>{
    if (modaltype==="onsave"){
    return(
    <div> {getCheckedServices(services).length>0 && <button type="button"   className="btn btn-primary" onClick={saveData}>Confirmar</button>}
            <button type="button" className="btn btn-secondary" onClick={onClose}> Atrás</button></div>
    )
  }
else{return( <div>
<button type="button" className="btn btn-primary"  onClick={onClose}> Agregar</button>
<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>{onClose();setDate((new Date()))}} > Salir</button></div>)}
}
  return (
    toShow && (
       <div className="modal fade show" style={{ display: 'block' }} aria-labelledby="Modal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="Modal">{tipomodal==="onsave"?(getCheckedServices(services).length>0 ?"¿Quieres guardar esta disponibilidad horaria solo para esta semana?":"No has agregado prestaciones"):"No agregaste ningún horario"}</h5>
            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
          </div>

          <div className="modal-footer">
            {children(tipomodal)}
          </div>
        </div>
      </div>
    </div>
    )
  );
};
ModalButton.propTypes = {
  toShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  getCheckedServices: PropTypes.func,
  services:PropTypes.array,
  setSavedData:PropTypes.func,
  saveData: PropTypes.func,
  setDate:PropTypes.func
};

export default ModalButton;
